import { createNew } from '@/apis/feed'

function useTracking() {
  const owner = ref<IOwner>()
  const page = ref<IPage>()
  const startTime = ref()
  const endTime = ref()
  const timestamp = useTimestamp()
  const focused = useWindowFocus()
  const route = useRoute()
  const { data } = useFetch('https://api.ipregistry.co/?key=tryout')
  const mobileDetector = inject('mobile-detect')

  const isAllowed = computed(() => (route.name as string).includes('link'))
  const duration = computed(() => (endTime.value - startTime.value) / 1000)
  const usingMetadata = computed<IFeedMetadata>(() => ({
    locationRaw: `${data.value?.location.country.name}, ${data.value?.location.city}`,
    location: `${data.value?.location.country.name}, ${data.value?.location.city} - <img src=\"${data.value?.location.country.flag.twemoji}\" width="20px" alt=""IMG />`,
    duration: duration.value,
    device: mobileDetector.isDesktop() ? 'Desktop' : 'Mobile',
  }))

  onMounted(() => {
    startTime.value = timestamp.value
  })

  onBeforeUnmount(() => {
    endTime.value = timestamp.value
    view()
  })

  whenever(() => !focused.value, () => {
    endTime.value = timestamp.value
    view()
  })

  const syncOwner = (val) => {
    owner.value = val
  }

  const syncPage = (val) => {
    page.value = val
  }

  const view = useOnce(() => {
    if (!isAllowed.value)
      return

    const { mutate: onCreateNew, onDone } = createNew({ linkId: route.params.slug as string, action: EnumFeedAction.Viewer, details: usingMetadata.value.location, metadata: usingMetadata.value })
    const { execute } = useSendEmail({
      to: owner.value?.attributes.email,
      subject: `Your ${route.params.name} got a new view`,
      text: 'ViewLink',
      metadata: {
        page_name: page.value?.attributes.Title,
        link_name: route.params.name,
        user_name: owner.value?.attributes.username,
        company_name: route.params.domain,
        location: usingMetadata.value.locationRaw,
      },
    })

    onDone(execute)

    return onCreateNew()
  })

  const click = useOnce((details: string) => {
    if (!isAllowed.value)
      return

    const { mutate: onCreateNew, onDone } = createNew({ linkId: route.params.slug as string, action: EnumFeedAction.Clicked, details })
    // Deprecated
    // const { execute } = useSendEmail({
    //   to: owner.value?.attributes.email,
    //   subject: `${page.value?.attributes.Title} - ${[route.params.name, route.params.domain].join(', ')}`,
    //   text: `${EnumFeedAction.Clicked} \"${details}\"`,
    // })

    // onDone(execute)

    return onCreateNew()
  })

  const share = useOnce((details: (typeof EnumFeedActionShared)[keyof typeof EnumFeedActionShared]) => {
    if (!isAllowed.value)
      return

    const { mutate: onCreateNew, onDone } = createNew({ linkId: route.params.slug as string, action: EnumFeedAction.Shared, details })
    const { execute } = useSendEmail({
      to: owner.value?.attributes.email,
      subject: `Your ${route.params.name} got a new forward`,
      text: 'ShareLink',
      metadata: {
        page_name: page.value?.attributes.Title,
        link_name: route.params.name,
        channel_name: details,
        user_name: owner.value?.attributes.username,
        company_name: route.params.domain,
      },
    })

    onDone(execute)

    return onCreateNew()
  })

  return {
    view,
    click,
    share,
    syncOwner,
    syncPage,
  }
}

export default createGlobalState(useTracking)
