function useSendEmail({ to = '', subject = '', text = '', html = '', ...rest } = {}) {
  const { data, execute } = useFetch('/sendemail', {
    method: 'POST',
    body: {
      to, subject, text, html, ...rest,
    },
    immediate: false,
    baseURL: import.meta.env.VITE_APP_POSTMARK_API_URL as string
  })

  return {
    data,
    execute,
  }
}

export default useSendEmail
